<template>
  <div class="product-feeds-settings-page">
    <v-card
      v-if="isSettingSectionReady"
      class="pa-4"
    >
      <v-form v-model="isValidProductImportPreferenceForm">
        <div class="fs-16 c-primary">
          {{ $t('product_feeds_settings_page_feed_format_title') }}
        </div>
        <div class="c-grey mt-1">
          {{ $t('product_feeds_settings_page_feed_format_subtitle') }}
        </div>
        <v-row class="mt-2">
          <v-col>
            <div class="d-flex align-center">
              <div :style="{ width: '150px' }">
                <v-select
                  v-model="charsetSelect.model"
                  :items="charsetSelect.items"
                  :label="$t('product_feeds_settings_page_feed_format_charset')"
                  hide-details
                  dense
                  class="f-13 mr-3"
                />
              </div>
              <div
                class="d-flex align-center mr-3"
                :style="{ width: '150px' }"
              >
                <v-text-field
                  v-model="separatorTextfield.model"
                  :label="$t('product_feeds_settings_page_feed_format_separator')"
                  :rules="[$required]"
                  hide-details
                  dense
                  class="f-13 mr-2"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t('product_feeds_settings_page_feed_format_separator_help_tooltip') }}</span>
                </v-tooltip>
              </div>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :icon="true"
                    class="v-btn-square"
                    @click="moreOptions = !moreOptions"
                  >
                    <v-icon v-if="!moreOptions">mdi-chevron-down</v-icon>
                    <v-icon v-else="moreOptions">mdi-chevron-up</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('product_feeds_settings_page_feed_format_see_more_options_tooltip') }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="moreOptions">
          <v-col>
            <div class="d-flex align-center">
              <div
                class="d-flex align-center mr-2"
                :style="{ width: '150px' }"
              >
                <v-text-field
                  v-model="gsubBackSlashTextfield.model"
                  :label="$t('product_feeds_settings_page_feed_format_gsub_backslash_label')"
                  hide-details
                  dense
                  class="f-13 mr-2"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("product_feeds_settings_page_feed_format_gsub_backslash_help_tooltip") }}</span>
                </v-tooltip>
              </div>
              <div
                class="d-flex align-center mr-2"
                :style="{ width: '150px' }"
              >
                <v-text-field
                  v-model="gsubCarriageReturn.model"
                  :label="$t('product_feeds_settings_page_feed_format_gsub_carriage_return_label')"
                  hide-details
                  dense
                  class="f-13 mr-2"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("product_feeds_settings_page_feed_format_gsub_carriage_return_help_tooltip") }}</span>
                </v-tooltip>
              </div>
              <div
                class="d-flex align-center mr-2"
              >
                <v-checkbox
                  :label="$t('product_feeds_settings_page_feed_format_remove_double_quote_label')"
                  v-model="removeDoubleQuoteCheckbox.model"
                  hide-details
                  dense
                  class="f-13 mr-2"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("product_feeds_settings_page_feed_format_remove_double_quote_label_help_tooltip") }}</span>
                </v-tooltip>
              </div>
              <div
                class="d-flex align-center"
              >
                <v-checkbox
                  :label="$t('product_feeds_settings_page_feed_format_remove_escape_double_quote_label')"
                  v-model="removeEscapeDoubleQuoteCheckbox.model"
                  hide-details
                  dense
                  class="f-13 mr-2"
                />
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
                  </template>
                  <span>{{ $t("product_feeds_settings_page_feed_format_remove_escape_double_quote_label_help_tooltip") }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="fs-16 c-primary mt-5">
          {{ $t('product_feeds_settings_page_default_language_flow_settings_title', { language: mainLanguage }) }}
        </div>
        <v-row class="mt-1">
          <v-col cols="12" md="10">
            <ProductFeeds
              :productFeeds="mainLocaleFeeds"
              :productsStatsData="productsStatsData"
              :required="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-btn
                :loading="loadingMappingColumns"
                :disabled="!isValidProductImportPreferenceForm"
                @click="setMappingColumns"
                dense
                small
                color='primary'
                class="f-13 mr-2"
              >
                {{ $t('product_feeds_settings_page_default_language_flow_settings_load_feed') }}
              </v-btn>
              <v-alert
                v-if="mainFeedSuccessMessage"
                dense
                outlined
                type="success"
                class="f-13 mb-0"
              >
                {{ mainFeedSuccessMessage }}
              </v-alert>
              <v-alert
                v-if="mainFeedErrorMessage"
                dense
                outlined
                type="error"
                class="f-13 mb-0"
              >
                {{ mainFeedErrorMessage }}
              </v-alert>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <v-card
      v-if="isMappingSectionReady"
      class="mt-5 pa-4"
    >
      <div class="fs-16 c-primary">
        {{ $t('product_feeds_settings_page_mapping_title') }}
      </div>
      <v-row>
        <v-col cols="12" md="5">
          <div class="c-grey mt-1">
            {{ $t('product_mapping_info_general') }}
          </div>
          <div class="mt-1">
            <v-row
              v-for="productMapping in productMappingsData"
              :key="productMapping.key"
              class="mt-0"
            >
              <v-col cols="12">
                <div class="d-flex align-center">
                  <span
                    class="mr-2 fs-13"
                    :class="isValidMapping(productMapping) ? 'c-primary' : 'c-error'"
                    :style="{ width: '150px' }"
                  >
                    {{ `${productMapping.title}:` }}
                  </span>
                  <w-autocomplete
                    v-model="productMapping.value"
                    :items="formattedMappingColumns"
                    :label="$t('product_feeds_settings_page_choose_column')"
                    minWidth="180px"
                    dense
                    rounded
                  />
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <div class="c-grey mt-1">
            {{ $t('product_mapping_categories') }}
          </div>
          <div class="mt-1">
            <v-row
              v-for="categoryMapping in categoryMappingsData"
              :key="categoryMapping.level"
              class="mt-0"
            >
              <v-col cols="12">
                <div class="d-flex align-center">
                  <span
                    class="mr-2 fs-13"
                    :style="{ width: $vuetify.breakpoint.smAndDown ? '150px' : '120px'}"
                    :class="isValidMapping(categoryMapping) ? 'c-primary' : 'c-error'"
                  >
                    {{ `${categoryMapping.title}:` }}
                  </span>
                  <w-autocomplete
                    v-model="categoryMapping.value"
                    :items="formattedMappingColumns"
                    :label="$t('product_feeds_settings_page_choose_column')"
                    @change="onChangeCategoryMapping()"
                    backgroundColor="white"
                    minWidth="180px"
                    dense
                    rounded
                  />
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-1">
              <v-col cols="12">
                <v-alert
                  dense
                  v-if="categoriesHasChangedMessage"
                  type="info"
                  class="f-13 mb-0"
                  outlined
                >
                  <div>
                    <div>
                      {{ $t('product_feeds_settings_page_category_has_changed_message_warning') }}
                    </div>
                    <div>
                      {{ $t('product_feeds_settings_page_category_has_changed_message_reapply_categories') }}
                    </div>
                  </div>
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row
        class="mt-6 lightgrey-border-top"
      >
        <v-col>
          <v-checkbox
            :label="$t('product_feeds_settings_page_allow_missing_products')"
            v-model="forbidMissingProducts.model"
            hide-details
            dense
            class="f-13 mr-2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-center">
            <v-btn
              dense
              @click="saveProductImportPreferenceAndStartImport(true)"
              :loading="isImportingProductFeed"
              :disabled="disabledSaveAndStartImport"
              small
              color='primary'
              class="f-13 mr-3"
            >
              {{ $t('product_feeds_settings_page_save_and_start_import') }}
            </v-btn>
            <v-alert
              v-if="feedsErrorMessage && isMainFeed"
              dense
              outlined
              type="error"
              class="f-13 mb-0"
            >
              {{ feedsErrorMessage }}
            </v-alert>
            <v-progress-linear
              v-else-if="showImportProductProgressBar && $vuetify.breakpoint.smAndUp"
              v-model="stepProgressTrace"
              :key="pollingImport"
              class="mr-3"
              height="25"
              color="green"
              style="pointer-events: none"
              rounded
              striped
            >
              <template v-slot:default="{ value }">
                <strong
                  v-if="importActionTrace.status === 'ok'"
                >
                  {{ stepProgressTrace }}% : {{ existingProductsTrace }} {{ $t('product_feeds_settings_page_products') }}
                </strong>
                <strong v-else>{{ stepProgressTrace }}%</strong>
              </template>
            </v-progress-linear>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card
      v-if="isProductsTableSectionReady"
      class="mt-5 pa-4"
    >
      <div class="d-flex align-center justify-space-between">
        <div class="fs-16 c-primary">
          {{ $t('product_feeds_settings_page_product_table_title') }}
        </div>
        <v-tooltip
          v-if="showExportProductsBtn"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="exportProducts"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon small>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('product_feeds_settings_page_export_products_tooltip') }}</span>
        </v-tooltip>
      </div>
      <v-row>
        <v-col>
          <div class="d-flex align-center">
            <div class="c-grey mt-1 mr-1">
            {{ $t('product_feeds_settings_page_product_table_products_sample') }}
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="f-12 c-lightgrey" small>mdi-information-outline</v-icon>
              </template>
              <div class="pa-2">
                <span>{{ $t('product_feeds_settings_page_product_table_tooltip') }}</span>
                  <ul>
                    <li>{{ $t('product_feeds_settings_page_product_table_tooltip_first_line') }}</li>
                    <li>{{ $t('product_feeds_settings_page_product_table_tooltip_second_line') }}</li>
                    <li>{{ $t('product_feeds_settings_page_product_table_tooltip_third_line') }}</li>
                  </ul>
                </div>
            </v-tooltip>
          </div>
          <ProductsTable
            :campaignId="campaignId"
            :productInfoTypes="productInfoTypes"
            :locales="localesWithFeeds"
          />
        </v-col>
      </v-row>
    </v-card>
    
    <v-card
      v-if="isLocalizedSectionReady"
      class="mt-5 pa-4"
    >
      <div class="fs-16 c-primary">
        {{ $t('product_feeds_settings_page_localized_language_flow_settings_title') }}
      </div>
      <div class="c-grey mt-1">
        {{ $t('product_feeds_settings_page_localized_language_flow_settings_subtitle') }}
      </div>
      <v-row class="mt-2">
        <v-col cols="12" md="10">
          <ProductFeeds
            :productFeeds="localizedLocaleFeeds"
            :productsStatsData="productsStatsData"
            :required="false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="d-flex align-center">
            <v-btn
              dense
              @click="saveProductImportPreferenceAndStartImport(false)"
              :loading="isImportingProductFeed"
              :disabled="disabledSaveAndStartImport"
              small
              color='primary'
              class="f-13 mr-2"
            >
              {{ $t('product_feeds_settings_page_localized_language_flow_start_import') }}
            </v-btn>
            <v-alert
              v-if="feedsErrorMessage && !isMainFeed"
              dense
              outlined
              type="error"
              class="f-13 mb-0"
            >
              {{ feedsErrorMessage }}
            </v-alert>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import _sortBy from 'lodash/sortBy'
  import _cloneDeep from 'lodash/cloneDeep'
  import yaml from 'js-yaml'
  import ProductsTable from './ProductsTable'
  import ProductFeeds from './ProductFeeds'

  export default {
    name: 'ProductImportPreferenceSettingsPage',
    components: {
      ProductsTable,
      ProductFeeds
    },
    props: {
      campaignId: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        isValidProductImportPreferenceForm: false,
        isImportingProductFeed: false,
        useFeedColumns: false,
        loadingMappingColumns: false,
        moreOptions: false,
        isMainFeed: true,
        mainFeedErrorMessage: '',
        feedsErrorMessage: '',
        mainFeedSuccessMessage: '',
        importActionTrace: undefined,
        pollingImport: 0,
        charsetSelect: {
          model: 'UTF-8',
          items: [
            { text: 'UTF-8', value: 'UTF-8' },
          ]
        },
        forbidMissingProducts: {
          model: true
        },
        separatorTextfield: {
          model: ';'
        },
        removeDoubleQuoteCheckbox: {
          model: false
        },
        removeEscapeDoubleQuoteCheckbox: {
          model: false
        },
        gsubBackSlashTextfield: {
          model: '_'
        },
        gsubCarriageReturn: {
          model: ''
        },
        mappingColumnsData: undefined,
        productFeedsData: undefined,
        categoryMappingsData: undefined,
        productsStatsData: undefined,
        productMappingsData: [
          { title: `${this.$t('product_mapping_product_name')} *`, value: null, mendatory: true, key: 'name' },
          { title: `${this.$t('product_mapping_code')} *`, value: null, mendatory: true, key: 'code' },
          { title: this.$t('product_mapping_parent_code'), value: null, mendatory: false, key: 'code_parent' },
          { title: `${this.$t('product_mapping_image')} *`, value: null, mendatory: true, key: 'image' },
          { title: this.$t('product_mapping_ean'), value: null, mendatory: false, key: 'ean' },
          { title: this.$t('product_mapping_sku'), value: null, mendatory: false, key: 'sku' },
          { title: this.$t('product_mapping_gtin'), value: null, mendatory: false, key: 'gtin' },
          { title: this.$t('product_mapping_mpn'), value: null, mendatory: false, key: 'mpn' },
          { title: this.$t('product_mapping_price'), value: null, mendatory: false, key: 'price' },
          { title: this.$t('product_mapping_url'), value: null, mendatory: false, key: 'url' },
          { title: this.$t('product_mapping_brand_name'), value: null, mendatory: false, key: 'brand_name' },
        ]
      }
    },
    methods: {
      async exportProducts() {
        try {
          const response = await this.$api.wizville.productImportPreferences.exportProducts(this.campaignId)
          if (response.status === 'ok') {
            this.$store.dispatch('notifySuccess', {
              message: this.$t('product_feeds_settings_page_export_products_success_message')
            })
          }
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message
          })
        }
      },
      async saveProductImportPreferenceAndStartImport(isMainFeed) {
        this.isMainFeed = isMainFeed
        this.isImportingProductFeed = true
        this.feedsErrorMessage = ''
        try {
          const formattedProductMapping = this.productMappingsData.map(productMapping => {
            return {
              key: productMapping.key,
              value: productMapping.value
            }
          })

          const productImportPreference = {
            charset: this.charsetSelect.model,
            separator: this.separatorTextfield.model,
            removeDoubleQuote: this.removeDoubleQuoteCheckbox.model,
            removeEscapeDoubleQuote: this.removeEscapeDoubleQuoteCheckbox.model,
            gsubBackSlash: this.gsubBackSlashTextfield.model,
            gsubCarriageReturn: this.gsubCarriageReturn.model,
            allowMissingProducts: !this.forbidMissingProducts.model,
            campaignId: this.campaignId,
            productFeeds: this.eligibleProductFeedsData.map(productFeed => {
              return {
                locale: productFeed.locale,
                urlLengow: productFeed.urlLengow,
                position: productFeed.position
              }
            }),
            productCategoryKeys: this.eligibleCategoryMappingsData.map(categoryMapping => {
              return {
                category_level: categoryMapping.level,
                category_key: categoryMapping.value
              }
            })
          }

          await this.$api.wizville.productImportPreferences.create(
            this.campaignId,
            formattedProductMapping,
            productImportPreference
          )
          setTimeout(() => { this.pollingImport += 1 }, 7000)
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message
          })
        }
      },
      async setMappingColumns() {
        this.loadingMappingColumns = true
        this.mainFeedErrorMessage = ''
        this.mainFeedSuccessMessage = ''
        try {
          const response = await this.$api.wizville.productImportPreferences.mappingColumns(
            this.campaignId,
            {
              feedUrlByLocale: this.mainFeedUrlByLocale(),
              charset: this.charsetSelect.model,
              separator: this.separatorTextfield.model,
              removeDoubleQuote: this.removeDoubleQuoteCheckbox.model,
              removeEscapeDoubleQuote: this.removeEscapeDoubleQuoteCheckbox.model,
              gsubBackSlash: this.gsubBackSlashTextfield.model,
              gsubCarriageReturn: this.gsubCarriageReturn.model
            }            
            
          )
          if (response.status === 'ok') {
            this.$store.dispatch('notifySuccess', {
              message: this.$t('product_feeds_settings_page_default_language_flow_settings_load_feed_success')
            })
            this.mappingColumnsData = response.columns
            this.mainFeedSuccessMessage = this.$t(
              'product_feeds_settings_page_default_language_flow_settings_success_message', {
                productRowsLength: response.productRowsLength
              }
            )
            this.useFeedColumns = true
            this.checkProductMappingsData()
          } else {
            this.mainFeedErrorMessage = response.message
          }
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message
          })
        }
        this.loadingMappingColumns = false
      },
      async fetchProductsStats() {
        try {
          const response = await this.$api.wizville.productImportPreferences.productsStats(
            this.campaignId
          )
          if (response.status === 'ok') {
            return response.productsStats
          } else {
            return false
          }
        } catch (e) {
          this.$store.dispatch('notifyError', {
            message: e.message
          })
          return false
        }
      },
      onChangeCategoryMapping() {
        const categoryMappingWithoutNullValue = this.categoryMappingsData.filter(
          categoryMapping => categoryMapping.value !== null && categoryMapping.value !== 'noSelection'
        ) || []

        const newCategoryMapping = categoryMappingWithoutNullValue.map((categoryMapping, index) => {
          return {
            ...categoryMapping,
            title: this.categoryTitle(index),
            level: index,
            mendatory: index === 0,
          }
        }) || []

        const level = newCategoryMapping.length
        let newEmptyCategoryMapping = {
          title: this.categoryTitle(level),
          value: null,
          level: level,
          mendatory: !!(level === 0)
        }
        this.categoryMappingsData = [...newCategoryMapping, newEmptyCategoryMapping]
      },
      indexToExcelColumn(index) {
        let columnLetter = ''
        const ASCII_CODE_A = 65
        const NB_LETTER_IN_ALPHABET = 26
        while (index >= 0) {
          columnLetter = String.fromCharCode(ASCII_CODE_A + (index % NB_LETTER_IN_ALPHABET)) + columnLetter
          index = Math.floor(index / NB_LETTER_IN_ALPHABET) - 1
        }
        return columnLetter
      },
      feedUrlByLocale() {
        return this.eligibleProductFeedsData.reduce((acc, productFeed) => {
          acc[productFeed.locale] = productFeed.urlLengow
          return acc
        }, {})
      },
      mainFeedUrlByLocale() {
        return this.eligibleProductFeedsData.filter(productFeed => productFeed.locale === this.mainLocale).reduce((acc, productFeed) => {
          acc[productFeed.locale] = productFeed.urlLengow
          return acc
        }, {})
      },
      isValidMappings(mappings) {
        return !mappings?.some(mapping => {
          return mapping.mendatory && (mapping.value === null || mapping.value === 'noSelection')
        })
      },
      isValidMapping(mapping) {
        if (mapping.mendatory) {
          return mapping.value !== null && mapping.value !== 'noSelection'
        } else {
          return true
        }
      },
      categoryTitle(level) {
        return level === 0 ? this.$t('product_mapping_category') : this.$t('product_mapping_sub_category', { level: level })
      },
      language(locale) {
        return this.$t(`languages.${locale}`)
      },
      setProductMappingsData() {
        if (this.productInfoTypes?.length) {
          this.productMappingsData = this.productMappingsData.map(productMapping => {
          let productInfoType = this.productInfoTypeByKey[productMapping.key]
            return {
              ...productMapping,
              value: productMapping.value || productInfoType?.columnName
            }
          })
        }
      },
      setProductImportPreferenceData() {
        if (this.productImportPreference) {
          this.charsetSelect.model = this.productImportPreference.charset
          this.separatorTextfield.model = this.productImportPreference.separator
          this.removeDoubleQuoteCheckbox.model = this.productImportPreference.removeDoubleQuote
          this.removeEscapeDoubleQuoteCheckbox.model = this.productImportPreference.removeEscapeDoubleQuote
          this.gsubBackSlashTextfield.model = this.productImportPreference.gsubBackSlash
          this.gsubCarriageReturn.model = this.productImportPreference.gsubCarriageReturn
          this.forbidMissingProducts.model = !this.productImportPreference.allowMissingProducts
        }
      },
      buildCategoryMappingsData() {
        let categoryMapping = []
        let maxCategoryLevel = -1
        if (this.productCategoryKeys?.length) {
          categoryMapping = this.productCategoryKeys.map((productCategoryKey) => {
            maxCategoryLevel = productCategoryKey.categoryLevel
            return {
              title: this.categoryTitle(productCategoryKey.categoryLevel),
              value: productCategoryKey.categoryKey,
              level: productCategoryKey.categoryLevel,
              mendatory: productCategoryKey.categoryLevel === 0,
            }
          })
        }
        let level = maxCategoryLevel + 1
        let emptyRow = {
          title: this.categoryTitle(level),
          value: null,
          mendatory: !!(level === 0),
          level: level
        }
        this.categoryMappingsData = [...categoryMapping, emptyRow]
      },
      buildProductFeedsData() {
        if (this.questionnairePreference) {
          this.productFeedsData = this.questionnairePreference?.languages.split(',').map((locale, index) => {
            return {
              locale: locale,
              urlLengow: null,
              position: index,
              language: this.$t(`languages.${locale}`)
            }
          })
        }
      },
      setProductFeedsData() {
        if (this.productFeeds && this.productFeedsData) {
          this.productFeedsData = this.productFeedsData.map((productFeedData) => {
            let productFeed = this.productFeedByLocale[productFeedData.locale] || productFeedData
            return {
              ...productFeedData,
              locale: productFeed.locale,
              urlLengow: productFeed.urlLengow
            }
          })
        }
      },
      checkProductMappingsData() {
        const mappingColumnNames = new Set(this.mappingColumnsData.map(column => column.columnName))
        this.productMappingsData = this.productMappingsData.map(productMapping => {
          return {
            ...productMapping,
            value: mappingColumnNames.has(productMapping.value) ? productMapping.value : null
          }
        })
      },
    },
    asyncComputed: {
      setProductStatsData: {
        async get() {
          const productsStatsData = await this.fetchProductsStats()
          if (productsStatsData) {
            this.productsStatsData = productsStatsData
          }
        }, watch: [ 'isImportingProductFeed' ]
      },
      productImportScheduledActionTrace: {
        async get() {
          let request = this.$basedRequest().select({
            product_import_scheduled_action_traces: [
              'status', 'error_message', 'info_locales',
              'info_total_products', 'info_imported_products',
              'info_current_step', 'info_total_steps', 'is_last'
            ]
          }).where({
            campaign_id: this.campaignId,
            is_last: true,
            status: ['processing', 'ok', 'ko']
          })

          const productImportScheduledActionTrace = (await this.$resolve(request))?.first() || []
          if (productImportScheduledActionTrace.status === 'processing') {
            this.isImportingProductFeed = true
            setTimeout(() => { this.pollingImport += 1 }, 1000)
          } else {
            this.isImportingProductFeed = false
            if (productImportScheduledActionTrace.status === 'ko') {
              this.feedsErrorMessage = productImportScheduledActionTrace.errorMessage
            }
          }
          this.importActionTrace = productImportScheduledActionTrace
        }, watch: [ 'pollingImport' ]
      },
      questionnairePreference: {
        async get() {
          let request = this.$basedRequest().select({
            questionnaire_preferences: [
              'languages'
            ]
          }).where({
            campaign_id: this.campaignId
          })
          const questionnairePreference = (await this.$resolve(request))?.first() || undefined
          return questionnairePreference
        }, default: undefined
      },
      productInfoTypes: {
        async get() {
          let request = this.$basedRequest().select({
            product_info_types: [
              'id', 'column_name', 'value', 'column_position'
            ]
          }).where({
            campaign_id: this.campaignId
          }).order(['column_position', 'asc'])
          const productInfoTypeDatas = (await this.$resolve(request))?.data?.productInfoTypes || []
          return productInfoTypeDatas
        },
        default: undefined,
        watch: [ 'isImportingProductFeed' ]
      },
      productCategoryKeys: {
        async get() {
          let request = this.$basedRequest().select({
            product_category_keys: [
              'category_level', 'category_key'
            ]
          }).where({
            campaign_id: this.campaignId
          }).order(['category_level', 'asc'])
          const productCategoryKeys = (await this.$resolve(request))?.data?.productCategoryKeys || []
          return productCategoryKeys
        }, default: undefined
      },
      productFeeds: {
        async get() {
          let request = this.$basedRequest().select({
            product_feeds: [
              'locale', 'url_lengow', 'position'
            ]
          }).where({
            campaign_id: this.campaignId
          })
          const productFeedsData = (await this.$resolve(request))?.data?.productFeeds || []
          return productFeedsData
        }, 
        default: undefined,
        watch: [ 'questionnairePreference' ]
      },
      productImportPreference: {
        async get() {
          let request = this.$basedRequest().select({
            product_import_preferences: [
              'allow_missing_products',
              'charset', 'separator',
              'remove_double_quote',
              'remove_escape_double_quote',
              'gsub_back_slash', 'gsub_carriage_return'
            ]
          }).where({
            campaign_id: this.campaignId
          })
          const productImportPreferencesData = (await this.$resolve(request))?.first() || undefined
          return productImportPreferencesData
        }, default: undefined
      },
      hasUserCampaignProductRestriction: {
        async get() {
          let request = this.$basedRequest().select({
            campaigns: [
              'has_user_campaign_product_restriction'
            ]
          }).where({
            id: this.campaignId
          })
          return (await this.$resolve(request))?.first()?.hasUserCampaignProductRestriction || false
        }, default: false
      }
    },
    computed: {
      categoriesHasChangedMessage() {
        if (this.productCategoryKeys.length === 0 || !this.hasUserCampaignProductRestriction) {
          return false
        }

        const origin_product_category_keys = _sortBy(this.productCategoryKeys.map(productCategoryKey => productCategoryKey.categoryKey), ['categoryLevel'],['asc'])
        const new_product_category_keys = _sortBy(this.categoryMappingsData.map(categoryMapping => categoryMapping.value).filter(value => value !== null), ['level'], ['asc'])

        return new_product_category_keys.join() !== origin_product_category_keys.join()
      },
      showExportProductsBtn() {
        return (
          !!this.productInfoTypes.length &&
          [undefined, 'ok'].includes(this.importActionTrace?.status) &&
          this.showImportProductProgressBar
        )
      },
      showImportProductProgressBar() {
        if (this.importActionTrace === undefined) {
          return false
        }
        if (this.importActionTrace?.status === 'ok' && !this.isImportingProductFeed) {
          return this.productInfoTypes?.length
        } else if (this.importActionTrace?.status === 'processing') {
          return this.isImportingProductFeed
        } else {
          return false
        }
      },
      disabledSaveAndStartImport() {
        return !this.isValidProductMappings || !this.isValidCategoryMappings
      },
      isSettingSectionReady() {
        return !!this.productFeedsData?.length
      },
      isMappingSectionReady() {
        return (
          (this.mappingColumnsData || this.productInfoTypes?.length) &&
          this.productFeedsData?.length &&
          this.categoryMappingsData?.length
        )
      },
      isLocalizedSectionReady() {
        return (
          this.productFeedsData?.length &&
          this.localizedLocaleFeeds.length &&
          this.productInfoTypes?.length
        )
      },
      isProductsTableSectionReady() {
        return this.productFeedsData?.length && this.productInfoTypes?.length
      },
      productFeedByLocale() {
        return this.productFeeds?.reduce((acc, productFeed) => {
          acc[productFeed.locale] = productFeed
          return acc
        }, {}) || {}
      },
      productInfoTypeByKey() {
        return this.productInfoTypes?.reduce((acc, productInfoType) => {
          acc[productInfoType.value] = productInfoType
          return acc
        }, {}) || {}
      },
      stepTrace() {
        if (this.importActionTrace.currentStep === null || this.importActionTrace.status === 'ok') {
          return 0
        } else {
          return this.importActionTrace.currentStep
        }
      },
      stepProgressTrace() {
        return Math.ceil(this.importActionTrace.infoCurrentStep / this.importActionTrace.infoTotalSteps * 100)
      },
      existingProductsTrace() {
        return this.importActionTrace?.infoTotalProducts
      },
      eligibleProductFeedsData() {
        return this.productFeedsData.filter(productFeed => 
          productFeed.urlLengow !== null && productFeed.locale !== null && productFeed.position !== null && productFeed.urlLengow !== ''
        )
      },
      eligibleCategoryMappingsData() {
        return this.categoryMappingsData.filter(categoryMapping =>
          categoryMapping.value !== null && categoryMapping.value !== 'noSelection'
        )
      },
      isValidProductMappings() {
        return this.isValidMappings(this.productMappingsData)
      },
      isValidCategoryMappings() {
        return this.isValidMappings(this.categoryMappingsData)
      },
      formattedMappingColumns() {
        let columns = []
        if (this.productInfoTypes?.length && !this.useFeedColumns) {
          columns = this.productInfoTypes.map((productInfoType) => {
            return {
              text: `${this.indexToExcelColumn(productInfoType.columnPosition)} - ${productInfoType.columnName}`,
              value: productInfoType.columnName,
            }
          })
        } else {
          columns = this.mappingColumnsData?.map((column) => {
            return {
              text: `${this.indexToExcelColumn(column.columnPosition)} - ${column.columnName}`,
              value: column.columnName,
            }
          })
        }

        columns.unshift(
          { text: this.$t('product_feeds_settings_page_choose_column'), value: 'noSelection' }
        )
        return columns
      },
      mainLocale() {
        return this.productFeedsData[0]?.locale
      },
      localesWithFeeds() {
        return Object.keys(this.productFeedByLocale)
      },
      mainLanguage() {
        return this.$t(`languages.${this.mainLocale}`)
      },
      mainLocaleFeeds() {
        return this.productFeedsData.filter(productFeed => productFeed.locale === this.mainLocale)
      },
      localizedLocaleFeeds() {
        return this.productFeedsData.filter(productFeed => productFeed.locale !== this.mainLocale)
      }
    },
    watch: {
      productInfoTypes() {
        this.setProductMappingsData()
      },
      productImportPreference() {
        this.setProductImportPreferenceData()
      },
      questionnairePreference() {
        this.buildProductFeedsData()
      },
      productFeeds() {
        if (this.questionnairePreference) {
          this.setProductFeedsData()
        }
      },
      productCategoryKeys() {
        this.buildCategoryMappingsData()
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .product-feeds-settings-page
    ::v-deep .v-label
      font-size: 13px;
</style>
